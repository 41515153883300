import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Error 404 - pàgina no trobada - jocsdetaula.cat" />
    <Header />
    <section className="pt-32 pb-8">
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="mb-2 font-bold text-2xl lg:text-4xl leading-tight text-orange-900">
          Error 404 - pàgina no trobada
        </h1>
        <p className="text-lg">
          Fes una tirada de percepció per a trobar el contingut que busques.
        </p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
